  <template>
  <v-container fluid>
    <v-card
      flat
      outlined
    >
      <v-card-title>{{$t('passport.export')}}</v-card-title>
      <v-divider></v-divider>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: 'PassportExport'
}
</script>

<style scoped>

</style>
